<template>
    <template v-if="state.isActiveTab === 1">
        <div class="w-90 center mb3">
            <div class="b pv3" style="border-top: 1px solid #e3e8ee">{{ headerTitle }}</div>
            <div class="pv3 flex flex-wrap justify-between items-center" style="border-bottom: 1px solid #e3e8ee">
                <div class="flex w-20-l w-60">
                    <div class="active-menu mr4 pointer" @click.prevent="activeTab1">{{ title1 }}</div>
                    <div class="mr4 pointer" @click.prevent="activeTab2">{{ title2 }}</div>
                    <div class="mr4 pointer" @click.prevent="activeTab3">{{ title3 }}</div>
                </div>
                <div class="flex flex-wrap justify-between items-center w-60-l w-100 pt0-l pt3">
                    <!-- <div class="card-box1">
                        <input type="date" name="" class="input-style w-100" placeholder="Search..." />
                    </div> -->
                    <div name="stats" id="stats" class="product-details-filter">
                        <span> Start date <input v-model="state.startDate" type="date" name="" class="input-style"/></span>
                        <span> End date <input v-model="state.endDate" type="date" name="" class="input-style"/></span>
                    </div>
                    <!-- <div class="searchmenu card-box1">
                        <img src="../../../assets/images/search.svg" class="pa1" alt="search icon" />
                        <input class="searchBox pl2" type="text" name="" placeholder="Search..." />
                    </div>
                    <div class="filter card-box1">
                        <div class="tc flex items-center ph3">
                        <div class="w-40"><img src="../../../assets/images/filter_list.svg" alt="filter icon" /></div>
                        <div class="pl2 w-50 tl">Filter</div>
                    </div> -->
                </div>
            </div>
        </div>

        <!-- desktop content display -->
        <!--        <div class="w-90 db-l dn center"></div>-->

        <div>
            <!-- Responsive table sales & Purchase -->
            <div class="scrollmenu w-90 center">
                <table class="w-100 tl mt3" cellspacing="0">
                    <thead>
                        <tr>
                            <th class="pb3 pr3 box-border-bottom tl">Invoice Date</th>
                            <th class="pb3 pr3 box-border-bottom tl">Invoice ID</th>
                            <th class="pb3 pr3 box-border-bottom tl">Description</th>
                            <th class="pb3 pr3 box-border-bottom tl">Total Amount</th>
                            <th class="pb3 pr3 box-border-bottom tl">Due Date</th>
                            <th class="pb3 pr3 box-border-bottom tl">Outstanding Bal.</th>
                            <th class="pb3 pr3 box-border-bottom tl">Prev. Payments</th>
                        </tr>
                    </thead>

                    <template v-for="item of itemArr1" v-bind:key="item._id">
                        <tr class="font-w1">
                            <td class="pv3 pr3 box-border-bottom tl">{{ item?.reference }}</td>
                            <td class="pv3 pr3 box-border-bottom tl">{{ formatDate(item?.date) || '-' }}</td>
                            <td class="pv3 pr3 box-border-bottom tl flex items-center">
                                <template v-if="item?.description?.length === 1">
                                    <router-link
                                        v-if="item?.description?.product"
                                        :to="{
                                            name: 'Product',
                                            params: {
                                                id: item?.description?.product
                                            }
                                        }"
                                    >
                                        {{ item?.description?.[0] || '-' }}
                                    </router-link>

                                    <span v-else> {{ item?.description?.[0] }}</span>
                                </template>

                                <template v-else>
                                    {{
                                        item?.description?.length > 1
                                            ? `${item?.description?.[0]} + (${item?.description?.length - 1}) more`
                                            : '-'
                                    }}</template
                                >
                            </td>
                            <td class="pv3 pr3 box-border-bottom tl">
                                {{ formatAmount(item?.amount || 0, $store.state.Settings?.currency) }}
                            </td>
                            <td class="pv3 pr3 box-border-bottom tl">{{ formatDate(item?.dueDate) || '-' }}</td>
                            <td class="pv3 pr3 box-border-bottom tl">
                                {{ formatAmount(item?.amountOwed || 0, $store.state.Settings?.currency) }}
                            </td>
                            <td class="pv3 pr3 box-border-bottom tl">
                                {{ formatAmount(item?.amountPaid || 0, $store.state.Settings?.currency) }} -
                                {{ formatDate(item?.updatedAt) || '-' }}
                            </td>
                        </tr>
                    </template>
                </table>
            </div>
            <div class="pb5 w-90 center">
                <div class="pa3 tc font-w1" style="background: #f6f8fe">
                    <a href="">View all status &#8594;</a>
                </div>
            </div>
        </div>
    </template>

    <template v-if="state.isActiveTab === 2">
        <div class="w-90 center mb3">
            <div class="b pv3" style="border-top: 1px solid #e3e8ee">{{ headerTitle }}</div>
            <div class="pv3 flex flex-wrap justify-between items-center" style="border-bottom: 1px solid #e3e8ee">
                <div class="flex w-20-l w-60">
                    <div class="mr4 pointer" @click.prevent="activeTab1">{{ title1 }}</div>
                    <div class="active-menu mr4 pointer" @click.prevent="activeTab2">{{ title2 }}</div>
                    <div class="mr4 pointer" @click.prevent="activeTab3">{{ title3 }}</div>
                </div>
                <div class="flex flex-wrap justify-between items-center w-60-l w-100 pt0-l pt3">
                    <div class="card-box1">
                        <input type="date" name="" class="input-style w-100" placeholder="Search..." />
                    </div>
                    <div class="searchmenu card-box1">
                        <img src="../../../assets/images/search.svg" class="pa1" alt="search icon" />
                        <input class="searchBox pl2" type="text" name="" placeholder="Search..." />
                    </div>
                    <div class="filter card-box1">
                        <!-- <div class="tc flex items-center ph3"> -->
                        <!-- <div class="w-40"><img src="../../../assets/images/filter_list.svg" alt="filter icon" /></div> -->
                        <!-- <div class="pl2 w-50 tl">Filter</div> -->
                    </div>
                </div>
            </div>
        </div>

        <!-- desktop content display -->

        <div>
            <!-- Responsive table sales & Purchase -->
            <div class="scrollmenu w-90 center">
                <table class="w-100 tl mt3" cellspacing="0">
                    <thead>
                        <tr>
                            <th class="pb3 pr3 box-border-bottom tl">Invoice Date</th>
                            <th class="pb3 pr3 box-border-bottom tl">Invoice ID</th>
                            <th class="pb3 pr3 box-border-bottom tl">Description</th>
                            <th class="pb3 pr3 box-border-bottom tl">Total Amount</th>
                            <th class="pb3 pr3 box-border-bottom tl">Due Date</th>
                            <th class="pb3 pr3 box-border-bottom tl">Outstanding Bal.</th>
                            <th class="pb3 pr3 box-border-bottom tl">Prev. Payments</th>
                        </tr>
                    </thead>

                    <template v-for="item of itemArr2" v-bind:key="item._id">
                        <tr class="font-w1">
                            <td class="pv3 pr3 box-border-bottom tl">{{ item?.reference }}</td>
                            <td class="pv3 pr3 box-border-bottom tl">{{ formatDate(item?.date) || '-' }}</td>
                            <td class="pv3 pr3 box-border-bottom tl flex items-center">
                                <template v-if="item?.description?.length === 1">
                                    <router-link
                                        v-if="item?.description?.product"
                                        :to="{
                                            name: 'Product',
                                            params: {
                                                id: item?.description?.product
                                            }
                                        }"
                                    >
                                        {{ item?.description?.[0] || '-' }}
                                    </router-link>

                                    <span v-else> {{ item?.description?.[0] }}</span>
                                </template>

                                <template v-else>
                                    {{
                                        item?.description?.length > 1
                                            ? `${item?.description?.[0]} + (${item?.description?.length - 1}) more`
                                            : '-'
                                    }}</template
                                >
                            </td>
                            <td class="pv3 pr3 box-border-bottom tl">
                                {{ formatAmount(item?.amount || 0, $store.state.Settings?.currency) }}
                            </td>
                            <td class="pv3 pr3 box-border-bottom tl">{{ formatDate(item?.dueDate) || '-' }}</td>
                            <td class="pv3 pr3 box-border-bottom tl">
                                {{ formatAmount(item?.amountOwed || 0, $store.state.Settings?.currency) }}
                            </td>
                            <td class="pv3 pr3 box-border-bottom tl">
                                {{ formatAmount(item?.amountPaid || 0, $store.state.Settings?.currency) }} -
                                {{ formatDate(item?.updatedAt) || '-' }}
                            </td>
                        </tr>
                    </template>
                </table>
            </div>
            <div class="pb5 w-90 center">
                <div class="pa3 tc font-w1" style="background: #f6f8fe">
                    <a href="">View all status &#8594;</a>
                </div>
            </div>
        </div>
    </template>

    <template v-if="state.isActiveTab === 3">
        <div class="w-90 center mb3">
            <div class="b pv3" style="border-top: 1px solid #e3e8ee">{{ headerTitle }}</div>
            <div class="pv3 flex flex-wrap justify-between items-center" style="border-bottom: 1px solid #e3e8ee">
                <div class="flex w-20-l w-60">
                    <div class="mr4 pointer" @click.prevent="activeTab1">{{ title1 }}</div>
                    <div class="mr4 pointer" @click.prevent="activeTab2">{{ title2 }}</div>
                    <div class="active-menu mr4 pointer" @click.prevent="activeTab3">{{ title3 }}</div>
                </div>
                <div class="flex flex-wrap justify-between items-center w-60-l w-100 pt0-l pt3">
                    <div class="card-box1">
                        <input type="date" name="" class="input-style w-100" placeholder="Search..." />
                    </div>
                    <div class="searchmenu card-box1">
                        <img src="../../../assets/images/search.svg" class="pa1" alt="search icon" />
                        <input class="searchBox pl2" type="text" name="" placeholder="Search..." />
                    </div>
                    <div class="filter card-box1">
                        <!-- <div class="tc flex items-center ph3"> -->
                        <!-- <div class="w-40"><img src="../../../assets/images/filter_list.svg" alt="filter icon" /></div> -->
                        <!-- <div class="pl2 w-50 tl">Filter</div> -->
                    </div>
                </div>
            </div>
        </div>

        <!-- desktop content display -->

        <div>
            <!-- Responsive table sales & Purchase -->
            <div class="scrollmenu w-90 center">
                <table class="w-100 tl mt3" cellspacing="0">
                    <thead>
                        <tr>
                            <th class="pb3 pr3 box-border-bottom tl">Invoice ID</th>
                            <th class="pb3 pr3 box-border-bottom tl">Invoice Date</th>
                            <th class="pb3 pr3 box-border-bottom tl">Transaction Type</th>
                            <th class="pb3 pr3 box-border-bottom tl">Description</th>
                            <th class="pb3 pr3 box-border-bottom tl">Total Amount</th>
                        </tr>
                    </thead>

                    <template v-for="item of itemArr3" v-bind:key="item._id">
                        <tr class="font-w1">
                            <td class="pv3 pr3 box-border-bottom tl">{{ item?.reference }}</td>
                            <td class="pv3 pr3 box-border-bottom tl">{{ formatDate(item?.date) || '-' }}</td>
                            <td class="pv3 pr3 box-border-bottom tl flex items-center ttc">
                                <img class="mr1 icon-default" src="../../../assets/images/file.svg" alt="" />
                                <span>{{ item?.type || '-' }}</span>
                            </td>
                            <td class="pv3 pr3 box-border-bottom tl">
                                <template v-if="item?.items?.length === 1">
                                    <router-link
                                        v-if="item?.items?.product"
                                        :to="{
                                            name: 'Product',
                                            params: {
                                                id: item?.items?.product
                                            }
                                        }"
                                    >
                                        {{ item?.items?.productName || '-' }}
                                    </router-link>

                                    <span v-else> {{ item?.items?.[0].productName }}</span>
                                </template>

                                <template v-else>
                                    <span v-if="item?.items?.length > 1">
                                        {{ `${item?.items?.[0]?.productName} + (${item?.items?.length - 1}) more` }}</span
                                    >
                                    <span v-else-if="item?.description">{{ item?.description }}</span>
                                    <span v-else>-</span>
                                </template>
                            </td>
                            <td class="pv3 pr3 box-border-bottom tl">
                                {{ formatAmount(item?.amount || 0, $store.state.Settings?.currency) }}
                            </td>
                        </tr>
                    </template>
                </table>
            </div>
            <div class="pb5 w-90 center">
                <div class="pa3 tc font-w1" style="background: #f6f8fe">
                    <a href="">View all status &#8594;</a>
                </div>
            </div>
        </div>
    </template>
</template>

<script>
import { reactive, watch } from 'vue'
import { formatAmount, formatDate } from '@/utils/lib'

export default {
    name: 'TableForPnR',
    props: {
        headerTitle: String,
        itemArr1: Array,
        itemArr2: Array,
        itemArr3: Array,
        title1: String,
        title2: String,
        title3: String,
        columnHeaders: Array
    },

    setup() {
        const state = reactive({
            // isActiveTab: false,
            isActiveTab: 1,
            startDate: null,
            endDate: null
        })

        const activeTab1 = () => {
            state.isActiveTab = 1
        }

        const activeTab2 = () => {
            state.isActiveTab = 2
        }

        const activeTab3 = () => {
            state.isActiveTab = 3
        }

        // const populateTable = (data) => {
        //   const element = {}
        //
        //   this.columnHeaders.forEach((item) => {
        //     // eslint-disable-next-line
        //     for (const key in data) {
        //       if (key === item.value) {
        //         element[key] = data[key]
        //       }
        //     }
        //   })
        //   return element
        // }

        watch(
            () => state.endDate,
            (next, prev) => {
                console.log(next, 'current date')
                if (!state.startDate) return
                const { startDate, endDate } = state
                const { id } = route.params
                const query = {
                    id,
                    startDate: new Date(startDate).toLocaleDateString('en-US'),
                    endDate: new Date(endDate).toLocaleDateString('en-US')
                }
                return store?.dispatch('Entity/getEntity', query)
            }
        )

        return { state, activeTab1, activeTab2, activeTab3, formatAmount, formatDate }
    }
}
</script>

<style scoped></style>
