<template>
    <div>
        <div v-if="state.isActiveTab === 'transactions'">
            <div class="w-90 center mb3">
                <div class="b pv3" style="border-top: 1px solid #e3e8ee">{{ headerTitle }}</div>
                <div class="pv3 flex flex-wrap justify-between items-center" style="border-bottom: 1px solid #e3e8ee">
                    <div class="flex w-20-l w-60">
                        <div class="active-menu mr4 pointer" @click.prevent="activeTab1">{{ title1 }}</div>

                        <div class="mr4 pointer" @click.prevent="activeTab2">{{ title2 }}</div>
                        <div class="mr4 pointer" @click.prevent="activeTab3">{{ title3 }}</div>
                    </div>
                    <div class="flex flex-wrap justify-between items-center w-60-l w-100 pt0-l pt3">
                        <div name="stats" id="stats" class="product-details-filter">
                            <span> Start date <input v-model="state.startDate" type="date" name="" class="input-style" /></span>
                            <span> End date <input v-model="state.endDate" type="date" name="" class="input-style" /></span>
                        </div>
                        <!-- <div class="searchmenu card-box1">
                            <img src="../../../assets/images/search.svg" class="pa1" alt="search icon" />
                            <input class="searchBox pl2" type="text" name="" placeholder="Search..." />
                        </div> -->
                        <!-- <div class="filter card-box1"> -->
                        <!-- <div class="tc flex items-center ph3"> -->
                        <!-- <div class="w-40"><img src="../../../assets/images/filter_list.svg" alt="filter icon" /></div>
                <div class="pl2 w-50 tl">Filter</div> -->
                        <!-- </div> -->
                    </div>
                </div>
            </div>

            <!-- desktop content display -->
            <div>
                <!-- Responsive table sales & Purchase -->
                <div class="scrollmenu w-90 center">

                     <table class="w-100 tl mt3" cellspacing="0">
                        <thead>
                            <tr>
                                <th class="pb3 pr3 box-border-bottom tl">Number</th>
                                <th class="pb3 pr3 box-border-bottom tl">Date</th>
                                <th class="pb3 pr3 box-border-bottom tl">Transaction Type</th>
                                <!-- <th class="pb3 box-border-bottom tl pr2">Customer Name</th>-->
                                <th class="pb3 box-border-bottom tl">Description</th>
                                <th class="pb3 box-border-bottom tl">Amount</th>
                            </tr>
                        </thead>
                         <tbody>
                            <tr v-for="item of itemArr1" v-bind:key="item._id" class="font-w1">
                                <td class="pv3 pr3 box-border-bottom tl">{{ item?.number || item?.reference }}</td>
                                <td class="pv3 pr3 box-border-bottom tl">{{ formatDate(item?.date) }}</td>
                                <td class="pv3 pr3 box-border-bottom tl flex items-center">
                                    <img class="mr1 icon-default" src="../../../assets/images/file.svg" alt="" />
                                    <span class="ttc">
                                        <span v-if="item.subtype === 'vat_received'"> {{ 'Vat Received' }}</span>
                                        <span v-else-if="item.type === 'inflows' || item.type === 'outflows'">
                                            {{ item?.subtype }}</span
                                        >
                                        <span v-else> {{ item?.type }}</span>
                                    </span>
                                </td>
                                <td class="pv3 pr3 box-border-bottom tl">
                                    <template v-if="item?.items?.length === 1">
                                        <router-link
                                            v-if="item?.items?.product"
                                            :to="{
                                                name: 'Product',
                                                params: {
                                                    id: item?.items?.product
                                                }
                                            }"
                                        >
                                            {{ item?.items?.[0]?.productName || item?.items?.[0]?.name || '-' }}
                                        </router-link>

                                        <span v-else> {{ item?.items?.[0].productName || item?.items?.[0]?.name }}</span>
                                    </template>

                                    <template v-else>
                                        <span v-if="item?.items?.length > 1">
                                            {{
                                                `${item?.items?.[0]?.productName || item?.items?.[0]?.name} + (${item?.items
                                                    ?.length - 1}) more`
                                            }}</span
                                        >
                                        <span v-else-if="item?.description">{{ item?.description }}</span>
                                        <span v-else>{{ item?.items?.[0]?.productName || item?.items?.[0]?.name || '-' }}</span>
                                    </template>
                                </td>
                                <td class="pv3 pr3 box-border-bottom tl">
                                    {{ formatAmount(item?.amount || 0, $store.state.Settings?.currency) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <!-- <div class="pb5">  -->
                    <!-- <div class="pa3 tc" style="background: #f6f8fe"> -->
                    <!-- <a href="">View all status &#8594;</a> -->
                    <!-- </div> -->
                    <!-- </div>  -->
                    <!-- </div> -->
                </div>
            </div>
        </div>

        <div v-if="state.isActiveTab === 'invoices'">
            <div class="w-90 center mb3">
                <div class="b pv3" style="border-top: 1px solid #e3e8ee">{{ headerTitle }}</div>
                <div class="pv3 flex flex-wrap justify-between items-center" style="border-bottom: 1px solid #e3e8ee">
                    <div class="flex w-20-l w-60">
                        <div class="mr4 pointer" @click.prevent="activeTab1">{{ title1 }}</div>
                        <div class="active-menu mr4 pointer" @click.prevent="activeTab2">{{ title2 }}</div>
                        <div class="mr4 pointer" @click.prevent="activeTab3">{{ title3 }}</div>
                    </div>
                    <div class="flex flex-wrap justify-between items-center w-60-l w-100 pt0-l pt3">
                        <!-- <div name="stats" id="stats" class="product-details-filter">
                            <span> Start date <input v-model="state.startDate" type="date" name="" class="input-style" /></span>
                            <span> End date <input v-model="state.endDate" type="date" name="" class="input-style" /></span>
                        </div> -->
                        <!-- <div class="searchmenu card-box1">
                            <img src="../../../assets/images/search.svg" class="pa1 h-20 w-10" alt="search icon" />
                            <input class="searchBox pl2" type="text" name="" placeholder="Search..." />
                        </div> -->
                        <!-- <div class="filter card-box1"> -->
                        <!-- <div class="tc flex items-center ph3"> -->
                        <!-- <div class="w-40"><img src="../../../assets/images/filter_list.svg" alt="filter icon" /></div> -->
                        <!-- <div class="pl2 w-50 tl">Filter</div> -->
                        <!--            </div>-->
                    </div>
                </div>
            </div>

            <div>
                <!-- Responsive table sales & Purchase -->
                <div class="scrollmenu w-100 center">
                    <TableForInvoices header-title="Invoices Transactions" :invoiceSummary="entity?.invoices" />
                    <!-- <table class="w-100 tl mt3" cellspacing="0"> -->
                        <!-- <thead>
                            <tr>
                                <th class="pb3 pr3 box-border-bottom tl">Number</th>
                                <th class="pb3 pr3 box-border-bottom tl">Date</th>
                                <th class="pb3 pr3 box-border-bottom tl">Transaction Type</th> -->
                                <!--                <th class="pb3 box-border-bottom tl pr2">Customer Name</th>-->
                                <!-- <th class="pb3 box-border-bottom tl">Description</th>
                                <th class="pb3 box-border-bottom tl">Amount</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="item of itemArr2" v-bind:key="item._id" class="font-w1">
                                <td class="pv3 pr3 box-border-bottom tl">{{ item?.reference }}</td>
                                <td class="pv3 pr3 box-border-bottom tl">{{ formatDate(item?.date) }}</td>
                                <td class="pv3 pr3 box-border-bottom tl flex items-center">
                                    <img class="mr1 icon-default" src="../../../assets/images/file.svg" alt="" />
                                    <span class="ttc">
                                        <span v-if="item.subtype === 'vat_received'"> {{ 'Vat Received' }}</span>
                                        <span v-else-if="item.type === 'inflows' || item.type === 'outflows'">{{
                                            item?.subtype
                                        }}</span>
                                        <span v-else> {{ item?.type }}</span>
                                    </span>
                                </td>
                                <td class="pv3 pr3 box-border-bottom tl">
                                    <template v-if="item?.items?.length === 1">
                                        <router-link
                                            v-if="item?.items?.product"
                                            :to="{
                                                name: 'Product',
                                                params: {
                                                    id: item?.items?.product,
                                                },
                                            }"
                                        >
                                            {{ item?.items?.productName || item?.items?.name || '-' }}
                                        </router-link>

                                        <span v-else> {{ item?.items?.[0].productName || item?.items?.[0]?.name }}</span>
                                    </template>

                                    <template v-else>
                                        <span v-if="item?.items?.length > 1">
                                            {{
                                                `${item?.items?.[0]?.productName || item?.items?.[0]?.name} + (${
                                                    item?.items?.length - 1
                                                }) more`
                                            }}</span
                                        >
                                        <span v-else-if="item?.description">{{ item?.description }}</span>
                                        <span v-else>{{ item?.items?.[0]?.productName || item?.items?.[0]?.name || '-' }}</span>
                                    </template>
                                </td>
                                <td class="pv3 pr3 box-border-bottom tl">
                                    {{ formatAmount(item?.amount || 0, $store.state.Settings?.currency) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="pb5"> -->
                        <!-- <div class="pa3 tc" style="background: #f6f8fe"> -->
                        <!-- <a href="">View all status &#8594;</a> -->
                        <!-- </div> -->
                    <!-- </div> -->
                </div>
            </div>
        </div>
        <div v-if="state.isActiveTab === 'quotations'">
            <div class="w-90 center mb3">
                <div class="b pv3" style="border-top: 1px solid #e3e8ee">{{ headerTitle }}</div>
                <div class="pv3 flex flex-wrap justify-between items-center" style="border-bottom: 1px solid #e3e8ee">
                    <div class="flex w-20-l w-60">
                        <div class="mr4 pointer" @click.prevent="activeTab1">{{ title1 }}</div>
                        <div class="mr4 pointer" @click.prevent="activeTab2">{{ title2 }}</div>
                        <div class="active-menu mr4 pointer" @click.prevent="activeTab3">{{ title3 }}</div>
                    </div>
                  
                </div>
            </div>

            <div>
                <!-- Responsive table sales & Purchase -->
                <div class="scrollmenu w-100 center">
                    <TableForInvoices header-title="Invoices Transactions" :invoiceSummary="entity?.invoices" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, watch, computed,onMounted } from 'vue'
import { formatAmount, formatDate, formatDateFilter, getLastSevenDaysDateRange } from '@/utils/lib'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import TableList from '@/views/Transactions/widgets/TableList'
import TableForInvoices from '@/views/Entities/widgets/TableForInvoices'

export default {
    name: 'TableTab',
    props: {
        headerTitle: String,
        itemArr1: Array,
        itemArr2: Array,
        itemArr3: Array,
        title1: String,
        title2: String,
        title3: String,
        columnHeaders: Array,
    },
    components: { TableList, TableForInvoices },

    setup() {
        const route = useRoute()
        const store = useStore()
        const role = computed(() => store?.state?.Auth?.role)
        const entity = computed(() => store?.state.Entity.entity)

        const state = reactive({
            isActiveTab: 'transactions',
            startDate: null,
            endDate: null,
        })

        const activeTab1 = () => {
            state.isActiveTab = 'transactions'
        }

        const activeTab2 = () => {
            state.isActiveTab = 'invoices'
        }

        const activeTab3 = () => {
            state.isActiveTab = 'quotations'
        }

        watch(
            () => state.endDate,
            (next, prev) => {
                //console.log(next, 'current date')
                if (!state.startDate) return
                const { startDate, endDate } = state
                const { id } = route.params
                const query = {
                    id,
                    startDate: new Date(startDate).toLocaleDateString('en-US'),
                    endDate: new Date(endDate).toLocaleDateString('en-US'),
                }
                return store?.dispatch('Entity/getEntity', query)
            }
        )

        // const populateTable = (data) => {
        //   const element = {}
        //
        //   this.columnHeaders.forEach((item) => {
        //     // eslint-disable-next-line
        //     for (const key in data) {
        //       if (key === item.value) {
        //         element[key] = data[key]
        //       }
        //     }
        //   })
        //   return element
        // }

        return {
            state,
            activeTab1,
            activeTab2,
            activeTab3,
            formatAmount,
            formatDate,
            role,
            formatDateFilter,
            entity,

        }
    },
}
</script>

<style scoped></style>
