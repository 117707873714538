<template>
    <div class="w-90 center mb3 pb5">
        <!-- <div class="b pv3" style="border-top: 1px solid #e3e8ee">{{ headerTitle }}</div> -->
        <div
            class="pv4 flex flex-wrap justify-between items-center"
        >
            <!-- <div class="flex scrollmenu w-40-l w-100 pb0-l pb3">
                <router-link
                    to="#"
                    @click="changeActiveTable('allInvoices')"
                    :class="state.tableType === 'allInvoices' ? 'active-menu mr3' : 'mr3'"
                    >All</router-link
                >
                <router-link
                    to="#"
                    @click="changeActiveTable('drafts')"
                    :class="state.tableType === 'drafts' ? 'active-menu mr3' : 'mr3'"
                    >Draft</router-link
                >
                <router-link
                    to="#"
                    @click="changeActiveTable('activePayments')"
                    :class="state.tableType === 'activePayments' ? 'active-menu mr3' : 'mr3'"
                    >Paid</router-link
                >
                <router-link
                    to="#"
                    @click="changeActiveTable('awaitingApprovals')"
                    :class="state.tableType === 'awaitingApprovals' ? 'active-menu mr3' : 'mr3'"
                    >Awaiting Payment</router-link
                >
                <router-link
                    to="#"
                    @click="changeActiveTable('overdue')"
                    :class="state.tableType === 'overdue' ? 'active-menu mr3' : 'mr3'"
                    >Overdue</router-link
                >
            </div> -->
            <div class="flex flex-wrap justify-between items-center w-50-l w-100 mt0-l mt2" style="margin-left: auto;">
                <select v-model="state.dateFilter" @change="changeDateFilter" class="card-box mb3 mb0-l" id="">
                    <option value="">Select</option>
                    <option value="last7Days">Last 7days</option>
                    <option value="currentMonth">This Month</option>
                    <option value="lastMonth">Last Month</option>
                </select>
                <input
                    type="date"
                    name=""
                    class="input-style card-box mb3 mb0-l"
                    placeholder="Search..."
                    v-model="state.dateSearch"
                    @change="changeDateSearch"
                />

                <div class="searchmenu card-box">
                    <img src="imgs/search.svg" class="pa1" alt="" />
                    <input class="searchBox pl2" type="text" name="" v-model="state.searchBox" placeholder="Search..." />
                </div>
                <div class="filter card-box">
                    <div class="tc flex items-center">
                        <img src="imgs/filter_list.svg" class="pl3" alt="" />
                        <router-link to="#" @click="filterTable()" class="pl4">Filter</router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="scrollmenu center">
            <table class="w-100 tl mt3" cellspacing="0">
                <thead>
                    <tr>
                        <th class="pb3 pr3 box-border-bottom tl">Number</th>
                        <th class="pb3 pr3 box-border-bottom tl">Ref</th>
                        <!-- <th class="pb3 pr3 box-border-bottom tl">Bill To</th> -->
                        <th class="pb3 box-border-bottom tl pr2">Issue date</th>
                        <th class="pb3 pr3 box-border-bottom tl">Due Date</th>
                        <th class="pb3 pr3 box-border-bottom tl">Amount</th>
                        <th class="pb3 box-border-bottom tl w-10">Due</th>
                        <th class="pb3 box-border-bottom tl">Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="invoice of state.activeTable" v-bind:key="invoice" class="font-w1">
                        <td class="pv3 pr3 box-border-bottom tl b">
                            <router-link :to="{ name: 'Invoice', params: { id: invoice?._id } }">
                                {{ invoice.number }}
                            </router-link>
                        </td>
                        <td class="pv3 pr3 box-border-bottom tl b">
                            <router-link :to="{ name: 'Invoice', params: { id: invoice?._id } }">
                                {{ invoice.reference }}
                            </router-link>
                        </td>
                        <!-- <td class="pv3 pr4 box-border-bottom tl" >{{invoice?.customer?.name}}</td> -->
                        <td class="pv3 pr3 box-border-bottom tl">{{ formatDate(invoice.dateIssued) }}</td>
                        <td class="pv3 pr3 box-border-bottom tl">{{ formatDate(invoice.dateDue) }}</td>
                        <td class="pv3 pr3 box-border-bottom tl">
                            {{ formatAmount(invoice.totalAmount, $store.state.Settings?.currency) }}
                        </td>
                        <td class="pv3 pr3 box-border-bottom tl">{{ getDaysFromToday(new Date(invoice.dateDue)) || 0 }}</td>
                        <td class="pv3 pr3 box-border-bottom tl">
                            {{
                                invoice.status.includes('void')
                                    ? 'Voided'
                                    : invoice.status.includes('paid')
                                    ? 'Paid'
                                    : invoice.status.includes('writeOff')
                                    ? 'writeOff'
                                    : state.tableType == 'allInvoices'
                                    ? invoice.status
                                    : tableStatus[state.tableType]
                            }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { reactive, watch, computed } from 'vue'
import { useStore } from 'vuex'

import {
    formatAmount,
    formatQuantity,
    getDaysFromToday,
    formatDate,
    getThisMonthDateRange,
    getLastMonthDateRange,
    getLastSevenDaysDateRange,
    getTotalPages,
    paginate2,
    paginate
} from '@/utils/lib'

export default {
    name: 'TableForInvoices',
    props: {
        headerTitle: String,
        invoiceSummary: Object
    },

    setup(props) {
        const store = useStore()
        const invoiceSummary = computed(() => store.state.Invoice.invoiceSummary)

        const intialState = {
            allInvoicesCount: 0,
            allInvoicesAmount: 0,
            draftCount: 0,
            draftAmount: 0,
            activePaymentCount: 0,
            activePaymentAmount: 0,
            awaitingApprovalCount: 0,
            awaitingApprovalAmount: 0,
            overDueCount: 0,
            overDueAmount: 0,

            allInvoices: [],
            drafts: [],
            activePayments: [],
            awaitingApprovals: [],
            overdue: [],

            activeTable: props.invoiceSummary?.[0]?.invoices,
            tableType: 'allInvoices',
            summaryType: 'all',
            dateFilter: '',
            dateSearch: '',
            searchBox: '',
            dateRange: null,
            pageNumber: 1,
            pageSize: 10,
            totalPages: null,
            lastInvoiceNumber: 0
        }

        const state = reactive(intialState)

        const changeActiveTable = tableName => {
            if (tableName === 'allInvoices') {
                state.activeTable = invoiceSummary?.[0]?.invoices
                state.tableType = 'allInvoices'
            } else if (tableName === 'drafts') {
                state.activeTable = invoiceSummary?.[2]?.invoices
                state.tableType = 'drafts'
            } else if (tableName === 'activePayments') {
                state.activeTable = invoiceSummary?.[1]?.invoices
                state.tableType = 'activePayments'
            } else if (tableName === 'awaitingApprovals') {
                state.activeTable = invoiceSummary?.[4]?.invoices
                state.tableType = 'awaitingApprovals'
            } else if (tableName === 'overdue') {
                state.activeTable = invoiceSummary?.[3]?.invoices
                state.tableType = 'overdue'
            }
        }

        const changeDateFilter = () => {
            // console.log('date filter', state.dateFilter);
            if (state.dateFilter === 'currentMonth') {
                state.dateRange = getThisMonthDateRange()
            } else if (state.dateFilter === 'lastMonth') {
                state.dateRange = getLastMonthDateRange()
            } else if (state.dateFilter === 'last7Days') {
                state.dateRange = getLastSevenDaysDateRange()
            }
            const query = {
                limit: '',
                page: '',
                date: state.dateRange,
                type: state.summaryType,
                search: state.searchBox
            }
            store.dispatch('Invoice/getInvoiceSummary', query)
        }

        const changeDateSearch = () => {
            const query = {
                limit: '',
                page: '',
                date: state.dateSearch,
                type: state.summaryType,
                search: state.searchBox
            }
            store.dispatch('Invoice/getInvoiceSummary', query)
        }

        // watch for invoice summary
        watch(
            () => invoiceSummary.value,
            (newValue, prevValue) => {
                if (newValue !== prevValue) {
                    const allInvoicesSummary = newValue.find(item => item.status === 'all')
                    const dratSummary = newValue.find(item => item.status === 'draft')
                    const activePaymentSummary = newValue.find(item => item.status === 'paid')
                    const awaitingApprovalSummary = newValue.find(item => item.status === 'awaiting_payment')
                    const overDueSummary = newValue.find(item => item.status === 'overdue')

                    state.allInvoicesCount = allInvoicesSummary.count
                    state.allInvoicesAmount = allInvoicesSummary.totalAmount
                    state.allInvoices = allInvoicesSummary.invoices

                    state.draftCount = dratSummary.count
                    state.draftAmount = dratSummary.totalAmount
                    state.drafts = dratSummary.invoices

                    state.activePaymentCount = activePaymentSummary.count
                    state.activePaymentAmount = activePaymentSummary.totalAmount
                    state.activePayments = activePaymentSummary.invoices

                    state.awaitingApprovalCount = awaitingApprovalSummary.count
                    state.awaitingApprovalAmount = awaitingApprovalSummary.totalAmount
                    state.awaitingApprovals = awaitingApprovalSummary.invoices

                    state.overDueCount = overDueSummary.count
                    state.overDueAmount = overDueSummary.totalAmount
                    state.overdue = overDueSummary.invoices

                    state.activeTable = state[state.tableType]

                    const lastnumber =
                        overDueSummary.count + activePaymentSummary.count + awaitingApprovalSummary.count + dratSummary.count

                    state.lastInvoiceNumber = lastnumber
                }
            }
        )

        return {
            state,
            formatAmount,
            formatDate,
            getDaysFromToday,
            changeActiveTable,
            changeDateFilter,
            changeDateSearch
        }
    }
}
</script>
