<template>
    <app-wrapper>
        <template v-slot:child>
            <!--Customer Transactions Detail -->
            <div class="w-90 center pt3 font-w1">
                <router-link :to="{ name: 'Entities', id: entity?.entityDetails?._id }"> &lt; Customer</router-link>
            </div>
            <template v-if="!entity"><Spinner /></template>

            <div class="w-90 center">
                <div class="flex flex-wrap justify-between items-center pv3 box-border-bottom">
                    <div>
                        <p class="pv2 f4 b">{{ `${entity?.entityDetails?.fullName || 'Entity'}'s` }} details</p>
                    </div>
                    <div class="scrollmenu">
                        <button
                            class="btn3"
                            v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(39)"
                            @click.prevent="archive(entity?.entityDetails)"
                            :disabled="state.disableArchive || entity?.entityDetails?.deletedAt !== undefined"
                        >
                            Archive customer
                        </button>
                        <router-link
                            v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(35)"
                            :to="{ name: 'EntityUpdate' }"
                        >
                            <button class="btn2 ml2">Edit customer</button>
                        </router-link>
                    </div>
                </div>
            </div>
            <!--Customer Transactions Detail -->

            <template v-if="entity">
                <!-- customer details, full name, phone-->
                <div class="w-90 center flex flex-wrap pt4">
                    <div class="w-25-l w-100 pa4 tc box-border">
                        <div class="flex justify-center items-center">
                            <img
                                v-if="entity?.entityDetails?.gravatarIcon"
                                :src="entity?.entityDetails?.gravatarIcon"
                                class="br-100"
                                alt=""
                            />

                            <div
                                v-else
                                class="flex justify-center items-center font-w3"
                                style="
                                    background: rgb(215, 222, 250);
                                    height: 150px;
                                    width: 150px;
                                    border-radius: 50%;
                                    font-size: 55px;
                                    color: rgb(19, 44, 140);
                                    margin-bottom: 20px;
                                "
                            >
                                {{ entityInitials }}
                            </div>
                        </div>
                        <div class="pt3 b" style="color: #193ab8">
                            {{ entity?.entityDetails?.fullName || '-' }}
                        </div>
                    </div>

                    <div class="w-25-l w-100 pa4 box-border">
                        <div class="pv2">
                            <div class="pb1" style="color: #55596ebf">Full Name</div>
                            <div class="font-w1">
                                {{ entity?.entityDetails?.fullName || '-' }}
                            </div>
                        </div>
                        <div class="pv2">
                            <div class="pb1" style="color: #55596ebf">Email</div>
                            <div class="font-w1">{{ entity?.entityDetails?.email || '-' }}</div>
                        </div>
                        <div class="pv2">
                            <div class="pb1" style="color: #55596ebf">Address</div>
                            <div class="font-w1">
                                {{ entity?.entityDetails?.address1 || entity?.entityDetails?.address2 || '-' }}
                            </div>
                        </div>
                        <div class="pv2">
                            <div class="pb2" style="color: #55596ebf">User role</div>
                            <div class="flex flex-wrap">
                                <div
                                    v-for="role in entity?.entityDetails?.roles"
                                    v-bind:key="role"
                                    class="tc pa1 mr2 mb2 ttc"
                                    style="background: #193ab8; color: white; width: 80px; border-radius: 50px"
                                    :style="setRolesBGAndTextColor(role)"
                                >
                                    {{ role }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="w-25-l w-100 pa4 box-border">
                        <div class="pv2 flex" style="justify-content: space-between; align-items: center">
                            <div>
                                <div class="pb1" style="color: #55596ebf">Phone no</div>
                                <div class="font-w1 flex" style="gap: 3px; cursor: pointer">
                                    <span>{{ entity?.entityDetails?.phone || '-' }}</span>
                                    <span v-if="entity?.entityDetails?.phone">
                                        <img
                                            src="@/assets/images/phone.svg"
                                            @click="makePhoneCall(entity?.entityDetails)"
                                            width="15"
                                            style="margin-right: 5px"
                                        />
                                        <img
                                            src="@/assets/images/whatsapp.svg"
                                            @click="openWhatsApp(entity?.entityDetails)"
                                            width="15"
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="pv2">
                            <div class="pb1" style="color: #55596ebf">Company name</div>
                            <div class="font-w1">{{ entity?.entityDetails?.company || '-' }}</div>
                        </div>
                        <div class="pv2">
                            <div class="pb1" style="color: #55596ebf">Website</div>
                            <div class="font-w1">{{ entity?.entityDetails?.website || '-' }}</div>
                        </div>
                        <div class="pv2">
                            <div class="pb1" style="color: #55596ebf">Postcode</div>
                            <div>{{ entity?.entityDetails?.postCode || '-' }}</div>
                        </div>
                    </div>

                    <div class="w-25-l w-100 pa4 box-border">
                        <div class="pv2">
                            <div class="pb1" style="color: #55596ebf">Gender</div>
                            <div class="font-w1">{{ entity?.entityDetails?.gender || '-' }}</div>
                        </div>
                        <div class="pv2">
                            <div class="pb1" style="color: #55596ebf">Note</div>
                            <div class="font-w1">{{ entity?.entityDetails?.description || '-' }}</div>
                        </div>
                    </div>
                </div>

                <!-- customer details, full name, phone-->

                <!-- sales, supplies, investments,debts & credits-->

                <div class="w-90 center flex flex-wrap pt4 mb4">
                    <!-- sales -->
                    <div class="w-20-l w-50 pa4 box-border">
                        <div class="pb2">Sales</div>
                        <div class="font-w2 f5 f4-l">
                            {{ formatAmount(entity?.entityDetails?.sales_amount, $store.state.Settings?.currency) }}
                        </div>
                    </div>
                    <!-- supplies -->
                    <div class="w-20-l w-50 pa4 box-border">
                        <div class="pb2">Supplies</div>
                        <div class="font-w2 f5 f4-l">
                            {{ formatAmount(entity?.entityDetails?.supplies_amount, $store.state.Settings?.currency) }}
                        </div>
                    </div>
                    <!-- investments -->
                    <div class="w-20-l w-50 pa4 box-border">
                        <div class="pb2">Investment</div>
                        <div class="font-w2 f5 f4-l">
                            {{ formatAmount(entity?.entityDetails?.capital_amount, $store.state.Settings?.currency) }}
                        </div>
                    </div>
                    <!-- debt -->
                    <div class="w-20-l w-50 pa4 box-border">
                        <div class="pb2">Debt</div>
                        <!-- <div class="font-w2 f5 f4-l">{{ formatAmount(entity?.entityDetails?.data?.receivable_balance) }}</div>-->
                        <div class="font-w2 f5 f4-l">
                            {{ formatAmount(entity?.entityDetails?.receivable_balance, $store.state.Settings?.currency) }}
                        </div>
                    </div>
                    <!-- credit -->
                    <div class="w-20-l w-50 pa4 box-border">
                        <div class="pb2">Credits</div>
                        <!--  <div class="font-w2 f5 f4-l">{{ formatAmount(entity?.entityDetails?.data?.payable_balance) }}</div>-->
                        <div class="font-w2 f5 f4-l">
                            {{ formatAmount(entity?.entityDetails?.payable_balance, $store.state.Settings?.currency) }}
                        </div>
                    </div>
                </div>
                <!-- sales, supplies, investments,debts & credits-->

                <!-- sales & purchase transaction -->
                <TableTab
                    header-title="Transactions"
                    :item-arr1="state.transactions?.data || []"
                    :item-arr2="state?.invoices?.data || []"
                    :item-arr3="entity?.quotations?.data || []"
                    title1="Transactions"
                    title2="Invoices"
                    title3="Quotations"
                />
                <!-- sales & purchase transaction -->

                <!-- sales & purchase transaction -->
                <!-- <TableForInvoices header-title="Invoices Transactions" :invoiceSummary="entity?.invoices" /> -->
                <!-- sales & purchase transaction -->

                <!-- debtor/creditor transaction -->
                <!-- <TableForPnR
                    header-title="Payables & Receivables"
                    :item-arr1="entity?.pNr?.debts"
                    :item-arr2="entity?.pNr?.credits"
                    :item-arr3="entity?.repayments"
                    title1="Debts"
                    title2="Credits"
                    title3="Repayments"
                /> -->
                <!-- debtor/creditor transaction -->

                <!--  Repayments transaction -->
                <!--                <TableTab header-title="Repayments" :item-arr1="entity?.repayments" title1="Repayments" />-->

                <!--  Other transaction -->
                <!-- <TableTab header-title="Others" :item-arr1="entity?.others" title1="Others" /> -->
                <!--  transaction -->
            </template>
        </template>
    </app-wrapper>
</template>

<script>
import { computed, onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import Swal from 'sweetalert2'
import AppWrapper from '@/layout/AppWrapper'
import { formatAmount, formatDate, setRolesBGAndTextColor } from '@/utils/lib'
import TableTab from '@/views/Entities/widgets/TableTab'
import TableForPnR from '@/views/Entities/widgets/TableForPnR'
import TableForInvoices from '@/views/Entities/widgets/TableForInvoices'
import Spinner from '@/components/Spinner'

export default {
    name: 'Entity',
    components: { AppWrapper, TableTab, Spinner, TableForPnR, TableForInvoices },

    setup() {
        const route = useRoute()
        const store = useStore()

        const entity = computed(() => store?.state.Entity.entity)
        const entityInitials = computed(() => store?.state.Entity.entityInitials)
        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)
        const role = computed(() => store?.state?.Auth?.role)


        const state = reactive({
            transactions: [],
            invoices: [],
            quotations: [],
            isPurchaseTab: false,
            isCreditorTab: false,
            columnHeaders: [
                { name: 'Invoice Date', itemName: '' },
                { name: 'Invoice ID', itemName: '' },
                { name: 'Description', itemName: '' },
                { name: 'Total Amount', itemName: '' },
                { name: 'Due Date', itemName: '' },
                { name: 'Previous Payments', itemName: '' },
            ],
            disableArchive: false,
        })

        const archive = (entity) => {
            Swal.fire({
                title: `Are you sure you want to archive this entity - ${entity.fullName}?`,
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Yes, archive it!',
                denyButtonText: `No, cancel!`,
            }).then((result) => {
                if (result.isConfirmed) {
                    store.dispatch('Entity/archiveEntity', entity._id).then((_) => {
                        state.disableArchive = true
                    })
                }
            })
        }
        const makePhoneCall = (entity) => {
            window.open(`tel:${entity.phone}`)
        }
        const openWhatsApp = (entity) => {
            const phoneNumber = entity.phone.replace(/\D/g, '').slice(-10);
            const url = `https://wa.me/+234${phoneNumber}`
            window.open(url, '_blank')
        }

        onMounted(() => {
            const today = new Date()
            const year = today.getFullYear()
            const month = today.getMonth()
            const day = today.getDate()
            const { id } = route.params
            const params = {
                limit: route.query?.limit || 25,
                page: route.query?.page || 1,
                sort: route.query?.sort || 'createdAt',
                mode: route.query?.mode || 'desc',
                filterType: 'fixed',
                start: route.query?.start || new Date(year, month, 1),
                end: route.query?.end || new Date(),
            }
            
            store?.dispatch('Entity/getEntity', { id })
            store?.dispatch('Entity/getEntityTransactions', { id, params })
                .then((resp) => {
                    if (resp.status) {
                        state.transactions = resp
                    }
                });
            store?.dispatch('Entity/getEntityInvoices', { id, params })
                .then((resp) => {
                    if (resp.status) {
                        state.invoices = resp
                    }
                });
            store?.dispatch('Entity/getEntityQuotations', { id, params })
                .then((resp) => {
                    if (resp.status) {
                        state.quotations = resp
                    }
                });
        })

        return {
            entity,
            entityInitials,
            formatAmount,
            formatDate,
            state,
            setRolesBGAndTextColor,
            archive,
            makePhoneCall,
            rolePermissions,
            role,
            openWhatsApp,
        }
    },
}
</script>

<style scoped></style>
